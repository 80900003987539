<template>
  <div class="home">
    <img src="@/assets/logo.png" alt="logo">
  </div>
</template>

<script>
export default { name: "Home" };
</script>

<style scoped>
.home {
  text-align: center;
}
</style>
